'use strict';

import UserStore from '@stores/UserStore';
import allNutrients from '@tables/nutrients';

function isNumeric(value) {
    if (typeof value === 'number') {
        return !isNaN(value);
    }

    if (typeof value !== 'string' || (typeof value === 'string' && value.trim() === '')) {
        return false;
    }

    return !isNaN(value) && !isNaN(parseFloat(value));
}

const searchableTypes = ['recipe', 'plan', 'combo', 'food', 'collection', 'category', 'brand'];

const paramsArrayMap = {
    availableOn: 'available_on',
    avoids: '!ingredient_tags',
    excludes: '!tags',
    exFood: '!foods',
    exMain: '!main_dish',
    exUuid: '!uuid',
    foods: 'foods',
    ingredientTags: 'ingredient_tags',
    tags: 'tags',
};

const paramsStringMap = {
    brandUuid: 'brand_uuid',
    category: 'category',
    exTerms: '!terms',
    main: 'main_dish',
    side: 'side_dish',
    merchantId: 'merchant.uuid',
    merchantName: 'merchant.name',
    pageType: 'page_type',
    productType: 'product_type',
    protection: 'protection',
    provider: 'mapped_from.provider',
    sizes: 'sizes',
    subrecipe: 'subrecipes.recipe',
    uuid: 'uuid',
};

const paramsBoolMap = {
    hasImage: 'has_image',
    hasNfp: 'has_nfp_image',
    isOwner: 'is_owner',
    include_library: 'include_library',
    library: 'library',
};

const paramsRangeMap = {
    cook: 'hands_off_timer',
    cost: 'avg_cost_per_serving',
    fresh: 'total_fresh',
    ingredients: 'total_ingredients',
    prep: 'hands_on_time',
    refuse: 'refuse',
    servings: 'servings',
    totalTime: 'total_time',
};

export function getParamsFromQuery(query, profile, types = ['recipe'], size = 12) {
    if (!profile) {
        profile = UserStore.getUser();
    }

    const {
        preferences: { recommended_mode_source },
        features,
    } = profile;

    let params = {
        terms: '',
        types: types.slice(0),
        filters: {
            library: false,
            tags: [],
            '!tags': [],
        },
        from: 0,
        size,
        sort_by: 'published',
        sort_params: {},
        include_merchants: features?.source_libraries || null,
    };

    if (query.lang) {
        params.language = query.lang;
    }

    if (query.sort) {
        params.sort_by = query.sort;
    }

    if (['asc', 'desc'].includes(query.order)) {
        params.sort_order = query.order;
    }

    if (query.unpublished) {
        params.admin_list = true;
    }

    if (query.terms) {
        params.terms = query.terms;
    }

    if (query.types) {
        if (typeof query.types === 'string') {
            params.types = [query.types];
        } else if (query.types.constructor === Array) {
            params.types = query.types.filter((type) => typeof type === 'string' && searchableTypes.includes(type));
        }
    }

    if (query.prescription && profile) {
        params.filters.prescriptions = profile.prescriptions;
    }

    if (query.strict_rx && profile) {
        params.filters.strict_rx = profile.prescriptions;
    }

    Object.entries(paramsArrayMap).forEach(([p, m]) => {
        if (query[p] && Array.isArray(query[p])) {
            params.filters[m] = query[p].filter((v) => typeof v === 'string');
        } else if (query[p] && typeof query[p] === 'string') {
            params.filters[m] = [query[p]];
        }
    });

    Object.entries(paramsStringMap).forEach(([p, m]) => {
        if (query[p] && typeof query[p] === 'string') {
            params.filters[m] = query[p];
        }
    });

    Object.entries(paramsBoolMap).forEach(([p, m]) => {
        if (query[p]) {
            params.filters[m] = true;
        }
    });

    Object.entries(paramsRangeMap).forEach(([key, field]) => {
        if (isNumeric(query[`${key}Max`])) {
            params.filters[field] = params.filters[field] || {};
            params.filters[field].lte = parseFloat(query[`${key}Max`]);
        }

        if (isNumeric(query[`${key}Min`])) {
            params.filters[field] = params.filters[field] || {};
            params.filters[field].gte = parseFloat(query[`${key}Min`]);
        }

        if (query[`${key}ShouldHaveValue`]) {
            params.filters[field] = params.filters[field] || {};
            params.filters[field].shouldHaveValue = true;
        }
    });

    Object.keys(allNutrients).forEach((nutrNo) => {
        const nutrient = allNutrients[nutrNo];

        if (!nutrient.Filter) {
            return;
        }

        const minKey = nutrient.Filter + 'Min';
        const maxKey = nutrient.Filter + 'Max';
        const shouldHaveValueKey = nutrient.Filter + 'ShouldHaveValue';

        const range = {};

        if (isNumeric(query[minKey])) {
            range.gte = parseFloat(query[minKey]) || 0;
        }

        if (isNumeric(query[maxKey])) {
            range.lte = parseFloat(query[maxKey]) || 0;
        }

        if (typeof query[shouldHaveValueKey] !== 'undefined') {
            range.shouldHaveValue = query[shouldHaveValueKey];
        }

        if (Object.keys(range).length > 0) {
            params.filters[nutrient.Filter] = range;
        }
    });

    return params;
}

export function getQueryFromParams(params, location = null, precise = false) {
    let query = {};

    // Certain valid query parameters aren't reflected/used in the search parameters, allow them here
    if (location && location.query) {
        const { rid, coid, clid, pid, bpid, fid, cid, altUser, advanced, create } = location.query;

        Object.assign(query, { rid, coid, clid, pid, bpid, fid, cid, altUser, advanced });
    }

    if (params.language) {
        query.lang = params.language;
    }

    if (params.sort_by && params.sort_by != 'published') {
        query.sort = params.sort_by;
    }

    if (params.sort_order) {
        query.order = params.sort_order;
    }

    if (params.admin_list) {
        query.unpublished = 1;
    }

    if (params.terms) {
        query.terms = params.terms;
    }

    query.types = searchableTypes.filter((type) => params.types?.includes(type));

    params.filters = params.filters || {};

    if (params.filters.prescriptions) {
        query.prescription = 1;
    }

    if (params.filters.strict_rx) {
        query.strict_rx = 1;
    }

    Object.entries(paramsArrayMap).forEach(([p, m]) => {
        if (params.filters[m]) {
            query[p] = params.filters[m];
        }
    });

    Object.entries(paramsStringMap).forEach(([p, m]) => {
        if (params.filters[m]) {
            query[p] = params.filters[m];
        }
    });

    Object.entries(paramsBoolMap).forEach(([p, m]) => {
        if (params.filters[m]) {
            query[p] = 1;
        }
    });

    Object.entries(paramsRangeMap).forEach(([key, field]) => {
        if (params.filters[field]?.lte !== undefined) {
            query[`${key}Max`] = params.filters[field].lte;
        }

        if (params.filters[field]?.gte !== undefined) {
            query[`${key}Min`] = params.filters[field].gte;
        }

        if (params.filters[field]?.shouldHaveValue) {
            query[`${key}ShouldHaveValue`] = 1;
        }
    });

    Object.keys(allNutrients).forEach((nutrNo) => {
        const nutrient = allNutrients[nutrNo];

        if (!nutrient.Filter) {
            return;
        }

        if (params.filters[nutrient.Filter] && !isNaN(params.filters[nutrient.Filter].gte)) {
            query[nutrient.Filter + 'Min'] = precise
                ? params.filters[nutrient.Filter].gte
                : Math.round(params.filters[nutrient.Filter].gte * 100) / 100;
        }

        if (params.filters[nutrient.Filter] && !isNaN(params.filters[nutrient.Filter].lte)) {
            query[nutrient.Filter + 'Max'] = precise
                ? params.filters[nutrient.Filter].lte
                : Math.round(params.filters[nutrient.Filter].lte * 100) / 100;
        }

        if (params.filters[nutrient.Filter] && params.filters[nutrient.Filter].shouldHaveValue !== undefined) {
            query[nutrient.Filter + 'ShouldHaveValue'] = params.filters[nutrient.Filter].shouldHaveValue;
        }
    });

    return query;
}
