'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import Helmet from 'react-helmet';
import $ from 'jquery';
import debounce from 'lodash.debounce';
import uuid from 'uuid';
import FileUploadPopUp from "../components/Input/FileUploadPopUp.react";
import DateSelector from '../components/DailyLog/DateSelector.react';
import MealRow from '../components/DailyLog/MealRow.react';
import EditNotes from '../components/DailyLog/EditNotes.react';
import DailyExtras from '../components/DailyLog/Extras.react';
import MacrosPieChart from '../components/Widgets/MacrosPieChart.react';
import ImgResized from '../components/Widgets/ImgResized.react';
import FileUpload from '../components/Input/FileUpload.react';
import LoginForm from '../components/Widgets/LoginForm.react';
import Footer from '../components/Widgets/footer/Footer.react';
import ExportModal from '../components/Nutrition/ExportModal.react';
import FoodEditorModal from '../components/Foods/Modals/FoodEditorModal';
import NutritionInfoModal from '../components/Nutrition/Modals/NutritionInfoModal.react';
import BarcodeScanner from '../components/Admin/Foods/Tools/BarcodeScanner.react';

import MealActions from '../actions/MealActions';
import MealStore from "../stores/MealStore";
import UserStore from "../stores/UserStore";
import UserActions from "../actions/UserActions";

import { getNutrientsForMeals, getIdealsFromEnvelope, nutrNoSortCmp } from '../utils/Nutrition';
import { aggregateMealPhotos, getPrimaryMeal, mealSortCompare, getAssetsForMeals, mealSortByCreated, getContentForMeal } from '../utils/Meals';

import Analytics from '../utils/Analytics';

import allNutrients from '../tables/nutrients';

import './DailyLog.scss';
import '../components/Search/Modals/AddSwapRecipe/Restaurants/NutritionAnalysis.scss';
import '../components/FeedModals.scss';

import modalStyles from '../jsx-styles/modals';

const nutrKeys = {
    'VEG': {
        NutrDesc: 'Veggies',
        Units: 'srv',
    },
    'FRU': {
        NutrDesc: 'Fruit',
        Units: 'srv',
    },
    '204': {
        NutrDesc: 'Fat',
        Units: 'g',
    },
    '208': {
        NutrDesc: 'Cal',
        Units: '',
    },
    '269': {
        NutrDesc: 'Sugar',
        Units: 'g',
    },
    '606': {
        NutrDesc: 'Sat Fat',
        Units: 'g',
    },
};

const allMealTypes = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];

export default class DailyLog extends Component {
    static contextTypes = {
        router: PropTypes.object,

        onModifyMeal: PropTypes.func,
        onModifyMeals: PropTypes.func,
        onRemoveMeals: PropTypes.func,
        showMealDetails: PropTypes.func,
        onSelectFood:  PropTypes.func,
        startDailyLogBarCode: PropTypes.func,

        startAddMeal: PropTypes.func,
        defaultMealType: PropTypes.string,
        isMobile: PropTypes.bool,
        confirm: PropTypes.func,
        isAndroid: PropTypes.bool
    };

    constructor(props, context) {
        super(props, context);

        const date = (props.params && props.params.date)
                   ? moment(props.params.date)
                   : moment();

        const user = UserStore.getUser();
        const allMeals = MealStore.getMeals();

        this.state = {
            user,
            date,
            allMeals,
            meals: [],

            recipes: [],
            brands: [],
            foods: [],
            logged: [],
            projectedOrLogged: [],
            ideals: {},
            remaining: {},

            editingNotes: {},
            scrollPos: 0,
            caloriesIncludesPlanned: user.show_planned_calories,
            isModalOpen: false,
            barcodeRetryCount: 0,
            lastScannedBarcode: null,
            currentBarcodMealType: null,
        };

        this.onScroll = debounce(this.onScroll, 150);
        this.scanners = {};
    }

    setScannerRef = (mealType, ref) => {
        this.scanners[mealType] = ref;
    }

    retryBarcodeScan = (mealType) => {
        if (mealType && this.scanners[mealType]) {
            this.scanners[mealType].retryBarcodeScan();
        }
    }

    onClickBarcodeIcon = (mealType) => {
        this.setState({currentBarcodMealType: mealType})
    }

    handleBarcodeRetryCount = () => {
        const { currentBarcodMealType } = this.state;

        const currentBarcode = this.state.missingBarcode;

        this.setState(prevState => {
            if (currentBarcode === prevState.lastScannedBarcode) {
                return {
                    barcodeRetryCount: prevState.barcodeRetryCount + 1,
                    lastScannedBarcode: currentBarcode,
                };
            } else {
                return {
                    barcodeRetryCount: 1,
                    lastScannedBarcode: currentBarcode,
                };
            }
        }, () => {
            if (this.state.barcodeRetryCount < this.scanners[currentBarcodMealType].rageScanningThreshold) {
                this.retryBarcodeScan(currentBarcodMealType);
            }
        });
    }

    resetBarcodeRetryCount = () => {
        this.setState({ barcodeRetryCount: 0 });
    }

    displayError = () => {
        const { currentBarcodMealType } = this.state;
        return this.state.barcodeRetryCount === this.scanners[currentBarcodMealType].rageScanningThreshold;
    }

    componentDidMount = () => {
        this.synchronizeAnalysis();
        const { date } = this.state;
        setTimeout(() => {
            MealActions.ensureDateRangeLoaded(date, date);
        });

        MealStore.addChangeListener(this.onMealStoreChange);
        Analytics.viewDailyLog();
    }

    componentWillUnmount = () => {
        MealStore.removeChangeListener(this.onMealStoreChange);
    };

    onMealStoreChange = () => {
        const allMeals = MealStore.getMeals();
        this.setState({ synced: false, allMeals }, () => {
            this.synchronizeAnalysis();
        });
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const date = (nextProps.params && nextProps.params.date)
                   ? moment(nextProps.params.date)
                   : this.state.date;
        const allMeals = MealStore.getMeals();

        this.setState({date, allMeals}, () => {
            this.synchronizeAnalysis();
        });
    }

    synchronizeAnalysis = () => {
        const { allMeals, user } = this.state;

        getAssetsForMeals(allMeals).then(({ recipes, details, foods, merchants, brands, locations }) => {

            const { date } = this.state;
            const contents = {...recipes, ...foods};
            const dailyMeals = allMeals.filter(m => date.isSame(m.date, 'day'));

            const { meals, logged, projectedOrLogged, nutrients, remaining, ideals } = this.analyzeDailyLog(user, dailyMeals, contents);

            this.setState({recipes, foods, brands, meals, logged, projectedOrLogged, nutrients, remaining, ideals, synced: true});
        });
    };

    analyzeDailyLog = (user, meals, contents) => {
        const { defaultMealType } = this.context;
        const { caloriesIncludesPlanned, date } = this.state;
        const today = moment().format('YYYY-MM-DD');

        const defaultMealIndex = allMealTypes.indexOf(defaultMealType);

        if (!user) {
            return { meals, nutrients: {}, remaining: null };
        }

        const allDayRx = user.prescriptions.filter(p => p.meal_type === 'all-day')[0];

        if (!allDayRx) {
            return { meals, nutrients: {}, remaining: null };
        }

        const ideals = getIdealsFromEnvelope(allDayRx.envelope);
        const logged = meals.filter(m => m.logged_portion || m.meal_type == 'note');
        const projectedOrLogged = meals.filter(m => moment(m.date).isSame(date, 'day'));
        const mealsForNutrients = moment(date).isAfter(today, 'day') || (moment(date).isSame(today, 'day') && caloriesIncludesPlanned) ? projectedOrLogged : logged;

        const roundValues = true;
        const nutrients = getNutrientsForMeals(mealsForNutrients, contents, user.portion, 0, roundValues);

        // Count the remaining amounts left / over for macros and calories.
        const remaining = {};
        Object.keys(allDayRx.envelope).forEach(nutrNo => {
            // If for some weird reason the min is filled in, but the max isn't, we can subsitute the min value for
            // the max value. Don't tell anyone tho ;)
            const range = allDayRx.envelope[nutrNo];
            const max = range.max;
            const min = range.min;
            const value = (nutrients[nutrNo] || 0);
            let percent = 100;

            if (max && value < max) {
                percent = Math.round(value / max * 100);
            } else if (min && value < min) {
                percent = Math.round(value / min * 100);
            }

            const out_of_range = projectedOrLogged.length > 0 &&
                ((max && value > max) || (min && value < min));

            remaining[nutrNo] = {
                nutrient: nutrKeys[nutrNo] || allNutrients[nutrNo],
                percent,
                max,
                min,
                max_diff: max - value,
                min_diff: min - value,
                max_diff_abs_rounded: Math.abs(Math.round(max - value)),
                min_diff_abs_rounded: Math.abs(Math.round(min - value)),
                out_of_range,
                value_rounded: Math.round(value),
                ...range,
            };
        });

        return { meals, logged, projectedOrLogged, nutrients, remaining, ideals };
    }

    closeModal = () => {
        const { router } = this.context;
        router.push('/meals');
    }

    closePhotoModal = () => {
        this.setState({photoModalUrl: null});
    }

    onChangeDate = (date) => {
        const { router } = this.context;

        MealActions.ensureDateRangeLoaded(date, date);

        const dateFormatted = date.format('YYYY-MM-DD');

        router.push(`/log/${dateFormatted}`);
    }

    getMode = (date, mealType) => {
        const { defaultMealType } = this.context;
        const defaultMealIndex = allMealTypes.indexOf(defaultMealType);

        let dayMode = 'past';
        const now = moment();

        if (now.isSame(date, 'day') && mealType == defaultMealType) {
            dayMode = 'current';
        } else if (now.isSame(date, 'day') && defaultMealIndex < allMealTypes.indexOf(mealType)) {
            dayMode = 'future';
        } else if (now.isBefore(date, 'day')) {
            dayMode = 'future';
        }

        return dayMode;
    }

    getModeFromMeal = (meal) => {
        return this.getMode(meal.date, meal.meal);
    }

    closeMealDetailsModal = () => {
        this.setState({mealsToShowNutrition: null});
    }

    showMealsNutrition = (meals) => {
        this.setState({mealsToShowNutrition: meals});
    }

    renderNutritionInfoModal = () => {
        const { mealsToShowNutrition, user, recipes, foods } = this.state;

        if (!(mealsToShowNutrition && mealsToShowNutrition.length)) {
            return null;
        }

        const contents = {...recipes, ...foods};
        const { titles, primary } = getPrimaryMeal(mealsToShowNutrition, recipes, foods);
        const nutrients = getNutrientsForMeals(mealsToShowNutrition, contents, user.portion);
        const content = getContentForMeal(primary, contents);

        // <NutritionInfo is going to multiply by the portion size anyway, so we have to divide to cancel it out
        Object.keys(nutrients).forEach(nutrNo => nutrients[nutrNo] = nutrients[nutrNo] / (user.portion || 1));

        return (
            <NutritionInfoModal
                title={`Nutrition for ${mealsToShowNutrition.length === 1 ? 'meal' : 'meals'}`}
                subtitle={titles.join(' + ')}
                brandName={content?.brand_name}
                profile={user}
                nutrients={nutrients}
                onClose={this.closeMealDetailsModal} />
        );
    }

    renderMeal = (meal, index, primary, originalIndex) => {
        const { showMealDetails, onModifyMeal, onRemoveMeals } = this.context;
        const {user, recipes, brands, foods} = this.state;

        return <MealRow key={index} dishIndex={originalIndex} meal={meal} user={user} brands={brands}
            recipes={recipes} foods={foods}
            isPrimary={primary && primary.uuid == meal.uuid ? true : false}
            mode={this.getModeFromMeal(meal)}
            onModifyMeal={onModifyMeal}
            onRemoveMeals={onRemoveMeals}
            showMealDetails={showMealDetails}
            showMealsNutrition={() => this.showMealsNutrition([meal])} />
    }

    aggregatePhotos = (meals) => {
        const { user, recipes, foods } = this.state;

        return aggregateMealPhotos(meals, recipes, foods, true);
    }

    onAddFood = (mealType) => {
        const { startAddMeal } = this.context;
        const { date } =  this.state;

        const mode = this.getMode(date, mealType);

        let options = { defaultMode: 'browser' };

        if (mode === 'past' || mode === 'current') {
            options.auto_log = true;
        }

        if (mode === 'past') {
            options.allowedModes = ['restaurants', 'store', 'favorites', 'ingredients', 'browser', 'use-own-recipe', 'create-custom'];
        }

        return startAddMeal(date, mealType, options);
    }

    onEditNotes = (mealType) => {
        const { editingNotes = {} } = this.state;

        editingNotes[mealType] = true;

        this.setState({editingNotes});
    }

    onSaveNotes = (mealType) => {
        const { editingNotes = {} } = this.state;

        editingNotes[mealType] = false;

        this.setState({editingNotes});
    }

    addUploadedPhoto = (mealType, file) => {
        // Get all the image_uuids from all the meals, combine them into a single array, uniqify the array
        // add the new UUID, then store it on the first meal, clearing the image_uuids from any other meals
        // on this particular day.
        const { onModifyMeals } = this.context;
        const { date } = this.state;
        const meals = this.state.meals.filter(m => m.meal === mealType && !m.deleted);

        let uuids = [];
        meals.forEach(meal => (meal.image_uuids || []).forEach(uuid => uuids.push(uuid)));

        // extract just the UUID at the end before the .jpg
        uuids.push(file.uuid);

        // Ensure all the uuids are unique
        uuids = uuids.filter((uuid, i) => uuids.indexOf(uuid) === i);

        // If we don't have any meals, create a new note and add it to that one.
        if (!meals.length) {
            meals.push({
                uuid: uuid.v4(),
                meal_type: 'note',
                date: date.format('YYYY-MM-DD'),
                meal: mealType,
            });
        }

        // Apply the full list to just the first meal.
        meals.forEach((meal, i) => meal.image_uuids = (i === 0) ? uuids : []);

        onModifyMeals(meals);
    }

    deleteUploadedPhoto = (url) => {
        const { meals } = this.state;
        const { onModifyMeals } = this.context;
        let uuid;

        if (url.indexOf('/file-proxy/') != -1) {
            const urlObj = new URL(url);
            uuid = urlObj.pathname.replace('/file-proxy/', '');
        } else {
            uuid = url.substr(url.length - 40, 36);
        }

        const dirtyMeals = [];

        meals.forEach(meal => {
            if (meal.image_uuids && meal.image_uuids.includes(uuid)) {
                meal.image_uuids = meal.image_uuids.filter(u => u !== uuid);
                dirtyMeals.push(meal);
            }
        });

        this.closePhotoModal();

        onModifyMeals(dirtyMeals);
    }

    showPhotoModal = (photoModalUrl, isPhotoModalDeletable = false) => {
        this.setState({photoModalUrl, isPhotoModalDeletable});
    }

    renderPhotoModal = () => {
        const { photoModalUrl, isPhotoModalDeletable } = this.state;

        if (!photoModalUrl) {
            return;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={this.closePhotoModal}
                closeModal={this.closePhotoModal}
                contentLabel="Daily Log"
                className="daily-log-photo-modal"
                style={modalStyles.minimalOverlay}
                closeTimeoutMS={250}>
                <div className="daily-log-photo-modal-container">
                    <div className="veil-container">
                        <button className="close-photo-modal-btn" onClick={this.closePhotoModal}>
                            <i className="icon-close-x" />
                        </button>
                        <div className="veil">&nbsp;</div>
                        <ImgResized src={photoModalUrl} />
                        {isPhotoModalDeletable ?
                            <button className="delete-photo-btn" onClick={() => this.deleteUploadedPhoto(photoModalUrl)}>delete photo</button>
                        : null}
                    </div>
                </div>
            </Modal>
        );
    }

    scrollable = null

    moveLeft = () => {
        if (!this.scrollable || !process.browser) {
            return;
        }

        const $scrollable = $(this.scrollable);
        let scrollLeft = $scrollable.scrollLeft();
        const parentWidth = $scrollable.parent().width();

        scrollLeft -= (parentWidth) || 250;

        if (scrollLeft < 0) {
            scrollLeft = 0;
        }

        $scrollable.animate({scrollLeft}, 250);
    }

    moveRight = () => {
        if (!this.scrollable || !process.browser) {
            return;
        }

        const $scrollable = $(this.scrollable);
        let scrollLeft = $scrollable.scrollLeft();
        const parentWidth = $scrollable.parent().width() + 1;

        scrollLeft += (parentWidth) || 250; // 250 = pixels

        $scrollable.animate({scrollLeft}, 250); // 250 = milliseconds
    }

    onScroll = () => {
        if (!this.scrollable) {
            return;
        }

        this.setState({scrollPos: this.scrollable.scrollLeft});
    }

    realizeNutritionScroller = (scrollable) => {
        if (scrollable && !this.scrollable) {
            scrollable.addEventListener('scroll', this.onScroll);
        }

        this.scrollable = scrollable;
    }

    getMacroGoalValue = (nutrient) => {
        if (nutrient.min && !nutrient.max) {
            return nutrient.min;
        }
        if (!nutrient.min && nutrient.max) {
            return nutrient.max;
        }
        if (nutrient.min && nutrient.max) {
            return (nutrient.min + nutrient.max) / 2;
        }
        return null;
    }

    onBarcodeNotFound = (barcode, isManualEntry, mealType) => {
        const { confirm, startDailyLogBarCode } = this.context;
        const { date } = this.state;

        startDailyLogBarCode(date, mealType, {auto_log: true});

        this.setState({isFoodsModalOpen: true, missingBarcode: barcode, barcodeMealType: mealType});
    }

    onBarcodeFound = (food, mealType) => {
        const { onSelectFood, startDailyLogBarCode } = this.context;
        const {date} = this.state;

        startDailyLogBarCode(date, mealType, {auto_log: true});
        onSelectFood(food, undefined, undefined, undefined, undefined, undefined, {source: 'Barcode Scan'});

        this.setState({isFoodsModalOpen: false});

        if (this.scanners[mealType] && typeof this.scanners[mealType].closeModal === 'function') {
            this.scanners[mealType].closeModal();
        }
    }

    closeCalorieModal = () => {
        this.setState({isModalOpen: false});
    }


    setCalorieIncludesPlanned = (caloriesIncludesPlanned) => {
        this.setState({caloriesIncludesPlanned});
        UserActions.updateSpecificMeta({show_planned_calories: caloriesIncludesPlanned});
    }

    renderCalorieModal = () => {
        const { isModalOpen, caloriesIncludesPlanned } = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                className="el-modal el-modal3 el-modal3-fixed"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeCalorieModal}
                closeModal={this.closeCalorieModal}
                contentLabel="Open Collection"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container calorie-modal">
                    <header>Which meals would you like to include in today's food tracker calculations?</header>
                    <div>
                        <ul>
                            <li onClick={() => this.setCalorieIncludesPlanned(true)}>
                                <button className="el-radio-btn" data-active={caloriesIncludesPlanned}>Used + Planned</button>
                                <p>Include food already eaten + food planned for later in the day</p>
                            </li>
                            <li onClick={() => this.setCalorieIncludesPlanned(false)}>
                                <button className="el-radio-btn" data-active={!caloriesIncludesPlanned} >Used Only</button>
                                <p>Only include food already eaten</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
        );
    }

    openCalorieModal = (mode) => {

        if (mode !== "current") {
            return;
        }

        this.setState({isModalOpen: true});

    }

    renderDailyLog = ()  => {
        const { user, recipes, foods, date, scrollPos, meals, logged, projectedOrLogged, nutrients, remaining, editingNotes, caloriesIncludesPlanned } = this.state;
        const { onModifyMeals, isMobile, isAndroid } = this.context;
        const contents = {...recipes, ...foods};
        const { capabilities = {} } = user;
        let mode = "current";
        let calorieText =  caloriesIncludesPlanned ? "used+planned" : "used";

        if (date) {
            if (moment().isBefore(date, 'day')) {
                mode = "future";
                calorieText = "planned";

            }
            if (moment().isAfter(date, 'day')) {
                mode = "past";
                calorieText = "used";
            }
        }

        const { hide_nutrition = false, inhibit_nutrition_export } = (user && user.preferences) || {};

        const nutrNos = remaining
                      ? Object.keys(remaining).filter(nutrNo => !['208'].includes(nutrNo))
                                              .sort(nutrNoSortCmp)
                      : null;

        const macrosGoals = {
            '208': remaining && remaining[208] ? this.getMacroGoalValue(remaining[208]) : null,
            '205': remaining && remaining[205] ? this.getMacroGoalValue(remaining[205]) : null,
            '204': remaining && remaining[204] ? this.getMacroGoalValue(remaining[204]) : null,
            '203': remaining && remaining[203] ? this.getMacroGoalValue(remaining[203]) : null
        };

        const hasAllMacros = macrosGoals['208'] && macrosGoals['205'] && macrosGoals['204'] && macrosGoals['203'];

        const dailyExtraMeals = meals.filter(meal => meal.meal_type === 'note' && meal.meal == 'daily');

        let dateFormatted = '';

        if (date) {
            if (moment().subtract(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Yesterday, ' + date.format('MMM Do');
            } else if (moment().isSame(date, 'day')) {
                dateFormatted = 'Today, ' + date.format('MMM Do');
            } else if (moment().add(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Tomorrow, ' + date.format('MMM Do');
            } else {
                dateFormatted = date.format('dddd, MMM Do');
            }
        }

        return (
            <div className="daily-log-page">
                {scrollPos > 10 ? <button className="scroll-left-btn" onClick={this.moveLeft}><i className="icon-chevron-left" /></button> : null}
                <button className="scroll-right-btn"onClick={this.moveRight}><i className="icon-chevron-right" /></button>

                {remaining ? <section className="nutrition-analysis" ref={this.realizeNutritionScroller}>

                    {remaining[208] ?
                        <ul className="calorie-budget">
                            <li className="target"><em>{Math.round(remaining[208].max || remaining[208].min)}</em> cals/day</li>
                            <li className="consumed" data-mode={mode} onClick={() => this.openCalorieModal(mode)}><em>{ remaining[208].value_rounded}</em> {mode == "current" ? <>{calorieText} <i className="icon-chevron-down"></i></> : calorieText} </li>
                            <li className="remaining"><em>{Math.round(remaining[208].max ? remaining[208].max_diff : remaining[208].min_diff)}</em> left</li>
                        </ul>
                    : null}

                    {logged.length > 0 || projectedOrLogged.length > 0 ? <MacrosPieChart nutrients={nutrients} macrosGoals={hasAllMacros ? macrosGoals : null} /> : null}

                    {nutrNos.map(nutrNo => {
                        const {
                            nutrient,
                            max_diff,
                            min_diff,
                            max_diff_abs_rounded,
                            min_diff_abs_rounded,
                            percent,
                            out_of_range,
                            value_rounded
                        } = remaining[nutrNo];

                        if (remaining[nutrNo] && max_diff >= 0 && !out_of_range) {
                            return (
                                <div className="macro-left" key={nutrNo}>
                                    <em data-out-of-range={out_of_range}>{nutrient.NutrDesc}</em>
                                    <div className="small-progress-bar">
                                        <div className="small-progress-bar-fill" style={{width: (percent + '%')}}></div>
                                    </div>
                                    {max_diff_abs_rounded} {nutrient.Units} left
                                </div>
                            );
                        }

                        if (remaining[nutrNo] && max_diff < 0 && max_diff_abs_rounded) {

                            return (
                                <div className="macro-over" key={nutrNo}>
                                    <em data-out-of-range={out_of_range}>{nutrient.NutrDesc}</em>
                                    <div className="small-progress-bar">
                                        <div className="small-progress-bar-fill" style={{width: '100%'}}></div>
                                    </div>
                                    {max_diff_abs_rounded} {nutrient.Units} over
                                </div>
                            );
                        }


                        if (remaining[nutrNo] && min_diff > 0 && min_diff_abs_rounded) {

                            return (
                                <div className="macro-over" key={nutrNo}>
                                    <em data-out-of-range={out_of_range}>{nutrient.NutrDesc}</em>
                                    <div className="small-progress-bar">
                                        <div className="small-progress-bar-fill" style={{width: (percent + '%')}}></div>
                                    </div>
                                    {min_diff_abs_rounded} {nutrient.Units} under
                                </div>
                            );
                        }

                        return (
                            <div className="macro-left" key={nutrNo}>
                                <em>{nutrient.NutrDesc}</em>
                                    {value_rounded} {nutrient.Units}
                            </div>
                        );
                    })}
                </section> : null}

                <section className="meals">

                        <div className="nutrition-export-container">
                            {!hide_nutrition && capabilities.export_nutrition && !inhibit_nutrition_export ?
                                <ExportModal className={isMobile ? "el-btn-icon-left el-text-btn" : "el-btn-icon-left el-medium-btn el-medium-wide-btn el-gray-btn"} />
                            : null}
                        </div>


                    {allMealTypes.map(mealType => {
                        const mealTypeMode = this.getMode(date, mealType);
                        const mealTypeMeals = meals.filter(m => m.meal === mealType && ['fresh', 'leftover', 'food'].includes(m.meal_type));
                        const loggedMealTypeMeals = mealTypeMeals.filter(m => m.logged_portion);
                        const mealTypeNotes = meals.filter(m => m.meal === mealType && ['note'].includes(m.meal_type));
                        const roundValues = true;

                        const mealTypeNutrients = mealTypeMode == 'future' || caloriesIncludesPlanned
                                                ? getNutrientsForMeals(mealTypeMeals, contents, (user && user.portion) || 1, 0,  roundValues)
                                                : getNutrientsForMeals(loggedMealTypeMeals, contents, (user && user.portion) || 1, 0,  roundValues);
                        const mealTypePhotos = this.aggregatePhotos(mealTypeMeals.concat(mealTypeNotes)).filter((photo) => mealTypeMode === 'future' || (!photo.isDeletable && photo.logged_portion) || photo.isDeletable);
                        const { primary } = getPrimaryMeal(mealTypeMeals, recipes, foods);

                        let hasMealTypeNotes = mealTypeNotes.map(m => m.notes).filter(v => v).length > 0;

                        // sort meals based on dishes as they appear on meal details page and capture thier index
                        const mealTypeMealsWithIndex = mealTypeMeals
                            .sort(mealSortCompare)
                            .map((meal, index) => ({meal, originalIndex: index}));

                        return (
                            <section className="with-header by-type" key={mealType} data-mode={mealTypeMode} data-testid={mealType}>
                                <header>
                                    <h3 className="meal-type-title">
                                        {mealType}
                                    </h3>
                                    <span className="header-buttons">
                                        <button className="add-food-btn2" onClick={() => this.onAddFood(mealType)}>
                                            <i className="icon-plus-thick" />
                                        </button>

                                        {mealTypeMeals.length > 0 ?
                                            <button className="show-nutrition-btn" onClick={() => mealTypeMode === 'future' ? this.showMealsNutrition(mealTypeMeals) : this.showMealsNutrition(loggedMealTypeMeals)}>
                                                <i className="icon-analyze" />
                                            </button>
                                        : null}
                                    </span>
                                    <p className="total-calories">
                                        {mealTypeMode === 'future' ? 'Projected' : 'Total'} Calories{' '}
                                        {mealTypeMode === 'current' && caloriesIncludesPlanned && (
                                        <span className="lowercase-text">
                                            (incl. planned)
                                        </span>
                                        )}
                                        &nbsp;
                                        <em>{Math.floor(mealTypeNutrients[208] || 0)}</em>
                                    </p>
                                </header>

                                <ul className="photos-list">
                                    {mealTypePhotos.map((photo, i) => (
                                        <li key={photo.url} onClick={() => this.showPhotoModal(photo.url, photo.isDeletable)}>
                                            <ImgResized className="meal-photo" src={photo.url} width={120} height={120} />
                                        </li>
                                    ))}

                                    {mealTypePhotos.filter(p => p.isDeletable).length < 10 && isAndroid &&
                                        <li data-camera-icon={true}>
                                            <FileUploadPopUp className="add-daily-log-photo-btn-popup medium-upload-btn" protection="private"
                                                onUploadSuccess={(file) => this.addUploadedPhoto(mealType, file)}
                                                button={<i className="feather feather-camera" />}
                                            />
                                        </li>
                                    }


                                    {mealTypePhotos.filter(p => p.isDeletable).length < 10 && !isAndroid &&
                                        <li data-camera-icon={true}>
                                            <FileUpload className="add-photo-btn add-daily-log-photo-btn" protection="private"
                                                onUploadSuccess={(file) => this.addUploadedPhoto(mealType, file)}
                                                button={<i className="feather feather-camera" />}
                                            />
                                        </li>
                                    }

                                    <li className="bar-code-icon-container">
                                        <BarcodeScanner className="scan-barcode-btn" ref={(ref) => this.setScannerRef(mealType, ref)}
                                            onClickBarcodeIcon={() => this.onClickBarcodeIcon(mealType)}
                                            onBarcodeNotFound={(barcode, isManualEntry) => this.onBarcodeNotFound(barcode, isManualEntry, mealType)}
                                            onBarcodeFound={(food) => this.onBarcodeFound(food, mealType)}
                                            barcodeContext={"Daily Log"} />
                                    </li>
                                </ul>

                                {mealTypeMeals.length ?
                                    <ul className="meal-list">
                                        {mealTypeMealsWithIndex
                                            .sort((a, b) => mealSortByCreated(a.meal, b.meal))
                                            .map(({meal, originalIndex}, index) => this.renderMeal(meal, index, primary, originalIndex))}
                                    </ul>
                                : null}

                                <footer>
                                    <button className="add-food-btn" onClick={() => this.onAddFood(mealType)}>
                                        add food
                                    </button>

                                    <EditNotes
                                        user={user}
                                        meals={mealTypeNotes}
                                        mealType={mealType}
                                        onModifyMeals={onModifyMeals}
                                        dateFormatted={dateFormatted}
                                        date={date}
                                        title={mealType}
                                        noteType={"notes"}/>
                                </footer>



                            </section>
                        );
                    })}
                </section>

                <DailyExtras meals={dailyExtraMeals} date={date} />
            </div>
        );
    }

    closeFoodEditorModal = () => {
        this.setState({isFoodsModalOpen: false, barcodeMealType: null, missingBarcode: '', barcodeRetryCount: 0, lastScannedBarcode: null});
    }

    onSaveFood = (food) => {
        const { onSelectFood } = this.context;

        Analytics.savedCustomFood({
            'Context': 'Daily Log',
            'Food UUID': food.uuid
        });

        onSelectFood(food);
        this.closeFoodEditorModal()
    }

    getDateName(date) {
        let dateName;

        if (moment().subtract(1, 'day').isSame(date, 'day')) {
            dateName = 'Yesterday';
        } else if (moment().isSame(date, 'day')) {
            dateName = 'Today';
        } else if (moment().add(1, 'day').isSame(date, 'day')) {
            dateName = 'Tomorrow';
        } else {
            dateName = moment(date).format('MMM D');
        }

        return dateName;
    }

    renderFoodEditorModal = () => {
        const { isFoodsModalOpen, missingBarcode = '', date, barcodeMealType } = this.state;
        const { profile } = this.props;

        if (!isFoodsModalOpen) {
            return;
        }

        const introMessage = missingBarcode
            ? <span>Sorry, that barcode was not found in our database. We'd love to add it though! Help us out by taking a few pictures, and we will add the barcode to our database within 72 business hours.</span>
            : <span>Sorry we didn't have the food you're looking for. We'd love to add it though! Help us out by taking a few pictures, and we will add it to our database within 72 business hours.</span>;

        const dateName = this.getDateName(date);

        const modalTitle = `Add ${barcodeMealType}, ${dateName}`;

        return (
            <FoodEditorModal
                handleBarcodeRetryCount={this.handleBarcodeRetryCount}
                resetBarcodeRetryCount={this.resetBarcodeRetryCount}
                displayError={this.displayError()}
                contentLabel="Edit Food Details"
                modalTitle={modalTitle}
                profile={profile}
                introMessage={introMessage}
                closeModal={this.closeFoodEditorModal}
                defaultBarcode={missingBarcode}
                onSaveFood={this.onSaveFood}
                retryBarcodeScan={() => this.retryBarcodeScan(barcodeMealType)}/>
        );
    }

    render = () => {
        const { user, date } = this.state;
        const { isMobile } = this.context;

        if (!user) {
            return (
                <div className="my-account-login">
                    <Helmet title="Please sign-in or register | EatLove" />
                    <LoginForm defaultSignUp={false} />
                    <Footer />
                </div>
            );
        }

        const { hide_nutrition = false } = (user && user.preferences) || {};
        const dateName = this.getDateName(date);

        return(
            <span>
                <Modal isOpen={true}
                    onRequestClose={this.closeModal}
                    closeModal={this.closeModal}
                    contentLabel="Daily Log"
                    className="el-modal el-modal1 daily-log-modal"
                    overlayClassName="el-modal-overlay"
                    closeTimeoutMS={250}>

                    <div className="el-modal-container el-modal1-container daily-log-modal-container" onClick={this.closeModal}>
                        <Helmet title="Daily Log | EatLove" />

                        <header onClick={(ev) => ev.stopPropagation()}>
                            <button className="el-modal-back-btn" onClick={this.closeModal}>
                                {!isMobile ? "Back" : null}
                            </button>

                            <h2>Daily Log, {dateName}</h2>

                            <div className="el-modal-controls el-modal1-controls">
                                <DateSelector date={date} onChange={this.onChangeDate} />
                            </div>
                        </header>

                        <div className="daily-log-scroll-container" onClick={(ev) => ev.stopPropagation()}
                            data-hide-nutrition={hide_nutrition}>
                            <section className="el-modal-body-container el-modal1-body-container">
                                {this.renderDailyLog()}
                            </section>
                        </div>
                    </div>
                </Modal>

                {this.renderPhotoModal()}
                {this.renderNutritionInfoModal()}
                {this.renderFoodEditorModal()}
                {this.renderCalorieModal()}
            </span>
        );
    }
}
